<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <path
        d="M12 23a7.5 7.5 0 0 0 7.5-7.5c0-.866-.23-1.697-.5-2.47-1.667 1.647-2.933 2.47-3.8 2.47 3.995-7 1.8-10-4.2-14 .5 5-2.796 7.274-4.138 8.537A7.5 7.5 0 0 0 12 23Zm.71-17.765c3.241 2.75 3.257 4.887.753 9.274-.76 1.333.202 2.991 1.737 2.991.688 0 1.384-.2 2.12-.595a5.5 5.5 0 1 1-9.088-5.412c.126-.118.765-.685.793-.71.424-.38.773-.717 1.118-1.086 1.23-1.318 2.114-2.78 2.566-4.462h.001Z"
      />
    </g>
    <defs>
      <clipPath id="a"><path d="M0 0h24v24H0z" /></clipPath>
    </defs>
  </svg>
</template>
